<template>
  <div class="scrollbar-box">
    <el-scrollbar class="detail-scrollbar">
      <div class="detail-box">
        <div class="detail-header">
          <div class="header-item">得分详情</div>
          <div class="header-item">学生提交答案</div>
        </div>
        <div class="detail-body">
          <div class="body-item">
            <div class="item-left">
              <span>问题类型</span>
              <el-input
                :readonly="true"
                v-model="detailData.type"
                size="small"
              ></el-input>
            </div>
            <div class="item-right"></div>
          </div>
          <div class="body-item">
            <div class="item-left">
              <span>考点名称</span>
              <el-input
                :readonly="true"
                v-model="detailData.name"
                size="small"
              ></el-input>
            </div>
            <div class="item-right"></div>
          </div>
          <div class="body-item">
            <div class="item-left">
              <span>客户问题</span>
              <el-input
                :readonly="true"
                v-model="detailData.question"
                size="small"
              ></el-input>
            </div>
            <div class="item-right"></div>
          </div>
          <div class="body-item">
            <div class="item-left">
              <template v-if="type !== 2">
                <span>得分操作</span>
                <el-input :readonly="true" v-model="detailData.score_operate" size="small"></el-input>
              </template>
            </div>
            <div class="item-right">
              <el-input
                :readonly="true"
                v-model="detailData.stu_score_operate"
                size="small"
              ></el-input>
            </div>
          </div>
          <template v-if="detailData.score_operate === '发送商品优惠券'">
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>优惠券</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.coupon"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_coupon"
                  size="small"
                ></el-input>
              </div>
            </div>
          </template>
          <template v-if="detailData.score_operate === '申请直赔'">
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>直赔原因</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.reason"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_reason"
                  size="small"
                ></el-input>
              </div>
            </div>
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>直赔金额</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.money"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_money"
                  size="small"
                ></el-input>
              </div>
            </div>
          </template>
          <template v-if="detailData.score_operate === '发送商品'">
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>商品</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.good_name"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_good_name"
                  size="small"
                ></el-input>
              </div>
            </div>
          </template>
          <template v-if="detailData.score_operate === '发送新建优惠券'">
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>商品</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.good_name"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_good_name"
                  size="small"
                ></el-input>
              </div>
            </div>
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>类型</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.coupon_type"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_coupon_type"
                  size="small"
                ></el-input>
              </div>
            </div>
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span style="margin-right: 8px">优惠</span>
                满
                <el-input
                  :readonly="true"
                  v-model="detailData.price_man"
                  style="width: 100px; margin: 0 8px"
                  size="small"
                ></el-input
                >元， 减<el-input
                  :readonly="true"
                  v-model="detailData.price_jian"
                  style="width: 100px; margin: 0 8px"
                  size="small"
                ></el-input
                >元
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                满
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_price_man"
                  style="width: 100px; margin: 0 8px"
                  size="small"
                ></el-input
                >元， 减<el-input
                  :readonly="true"
                  v-model="detailData.stu_price_jian"
                  style="width: 100px; margin: 0 8px"
                  size="small"
                ></el-input
                >元
              </div>
            </div>
            <div class="body-item">
              <div class="item-left" v-if="type !== 2">
                <span>有效期</span>
                <el-input
                  :readonly="true"
                  v-model="detailData.validity_time"
                  size="small"
                ></el-input>
              </div>
              <div class="item-left" v-if="type === 2"></div>
              <div class="item-right">
                <el-input
                  :readonly="true"
                  v-model="detailData.stu_validity_time"
                  size="small"
                ></el-input>
              </div>
            </div>
          </template>
          <div class="body-item" v-if="type !== 2">
            <div class="item-left">
              <span>解析</span>
              <el-input :readonly="true" v-model="detailData.analysis" size="small"></el-input>
            </div>
            <div class="item-right"></div>
          </div>
          <div class="body-item">
            <div class="item-left">
              <span>分值</span>
              <el-input
                :readonly="true"
                v-model="detailData.score"
                size="small"
              ></el-input>
            </div>
            <div class="item-right"></div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 30px" @click="toBack">
        <el-button>返回</el-button>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "ExamStoreCustomerDetail",

  data() {
    return {
      detailData: {},
      type: Number(localStorage.getItem("type")),
    };
  },
  mounted() {
    this.detailData = JSON.parse(localStorage.getItem("issueItem"));
  },
  methods: {
    toBack() {
      this.$emit("isShow", true);
    },
  },
};
</script>

<style scoped lang="scss">
.scrollbar-box {
  background-color: #fff;
  height: 100%;
}
.detail-scrollbar {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px 20px;
    }
  }
}
.detail-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  margin-top: 20px;
  .detail-header {
    display: flex;
    height: 60px;
    background: #f6f6f6;
    padding: 0 50px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    border-bottom: none;
    .header-item {
      display: flex;
      align-items: center;
      flex: 1;
      width: 1%;
      color: #666666;
      font-size: 16px;
    }
  }
  .detail-body {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .body-item {
      height: 70px;
      display: flex;
      padding: 0 50px;
      box-sizing: border-box;
      //align-items: center;
      border: 1px solid #e5e5e5;
      border-bottom: none;
      .item-left,
      .item-right {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;
        flex: 1;
        width: 1%;
        .el-input {
          margin-left: 8px;
          width: 290px;
        }
        &.item-left {
          span {
            min-width: 70px;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
