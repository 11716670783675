<template>
  <el-scrollbar :native="false" class="service-data-scrollbar">
    <div class="data-header" v-if="showbBreadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>新网店客户服务</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>新网店客户服务</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb  separator-class="el-icon-arrow-right" v-if="role == 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>新网店客户服务</el-breadcrumb-item>
            </el-breadcrumb>
    </div>
    <div class="service-data-table" v-if="showDetail">
      <div class="data-table-header">
        <div class="table-header-item" style="flex: 0.9; text-align: left">
          <span style="margin-left: 40px">机器人名称</span>
        </div>
        <div class="table-header-item" style="flex: 0.6">
          <span>问题类型</span>
        </div>
        <div class="table-header-item" style="margin-right: 10px">
          <span>客户问题</span>
        </div>
        <div class="table-header-item" v-if="role != 4" style="margin-right: 10px">
          <span>解析</span>
        </div>
        <div class="table-header-item" v-if="role != 4" style="margin-right: 10px">
          <span>不得分关键词</span>
        </div>
        <div class="table-header-item" v-if="role != 4" style="margin-right: 10px">
          <span>得分操作</span>
        </div>
        <div class="table-header-item" style="margin-right: 10px">
          <span>得分详情</span>
        </div>
        <div class="table-header-item" style="flex: 0.6">
          <span>得分（{{ score }}分）</span>
        </div>
      </div>
      <div class="data-table-body" v-if="robotList.length">
        <div class="table-body-item" v-for="(item, index) in robotList" :key="index">
          <div class="body-item-top">
            <div class="item-top-item" style="flex: 0.9; justify-content: start">
              <i style="margin-left: 40px" @click="item.isShow = !item.isShow" v-show="item.isShow" class="iconfont">&#xe65f;</i>
              <i style="margin-left: 40px" @click="item.isShow = !item.isShow" v-show="!item.isShow" class="iconfont">&#xe65d;</i>
              <span class="text-overflow">{{ item.robot_name }}</span>
            </div>
            <div class="item-top-item" style="flex: 0.6"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" v-if="role != 4" style="margin-right: 10px"></div>
            <div class="item-top-item" v-if="role != 4" style="margin-right: 10px"></div>
            <div class="item-top-item" v-if="role != 4" style="margin-right: 10px"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" style="flex: 0.6">
              <span class="text-overflow">{{ item.score }}</span>
            </div>
          </div>
          <div class="body-item-bottom" v-for="(issueItem, issueIndex) in item.question" :key="issueIndex" v-show="item.isShow">
            <div class="item-bottom-item" style="flex: 0.9"></div>
            <div class="item-bottom-item" style="flex: 0.6">
              <span>{{ issueItem.type }}</span>
            </div>
            <div class="item-bottom-item" style="margin-right: 10px">
              <el-input v-model="issueItem.question" :readonly="true" size="small"></el-input>
            </div>
            

            <div class="item-bottom-item" v-if="role!=4" style="margin-right: 10px">
              <el-input :readonly="true" v-model="issueItem.analysis" size="small"></el-input>
            </div>
            <template v-if="role!=4">
              <div class="item-bottom-item"  style="margin-right: 10px" v-if="issueItem.no_score_keyword">
                <el-input :readonly="true" v-model="issueItem.no_score_keyword" size="small"></el-input>
              </div>
              <div class="item-bottom-item" style="margin-right: 10px" v-else></div>
            </template>
            <template v-if="role!=4">
              <div class="item-bottom-item" style="margin-right: 10px" v-if="issueItem.no_score_keyword">
                <el-input :readonly="true" v-model="issueItem.score_operate" size="small"></el-input>
              </div>
              <div class="item-bottom-item" style="margin-right: 10px; justify-content: start" v-else>
                <span style="margin-left: 4px">{{ issueItem.score_operate }}</span>
              </div>
            </template>


            <div class="item-bottom-item" style="margin-right: 10px" v-if="issueItem.no_score_keyword">
              <el-input :readonly="true" v-model="issueItem.score_detail" size="small"></el-input>
            </div>
            <div class="item-bottom-item" style="margin-right: 10px" v-else>
              <el-link @click="toDetail(issueItem.score_detail)" :underline="false" type="success">查看详情</el-link>
            </div>
            <div class="item-bottom-item" style="flex: 0.6">
              <span class="text-overflow">{{ issueItem.score }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-else style="text-align: center; border: 1px solid #e5e5e5; line-height: 70px">暂无数据</div>
    </div>
    <ExamStoreCustomerDetail @isShow="isShow" v-else />
  </el-scrollbar>
</template>

<script>
import { getStuKefuExamDetail, getStuKefuTrainDetail } from "@/utils/apis";
import ExamStoreCustomerDetail from "./ExamStoreCustomerDetail.vue";
export default {
  name: "ExamStoreCustomer",
  components: { ExamStoreCustomerDetail },
  data() {
    return {
      robotList: [],
      score: "",
      showDetail: true,
      showbBreadcrumb: true,
      role: Number(localStorage.getItem("role")),
    };
  },
  mounted() {
    this.getExamStuOperationDetail();
    // console.log(this.role);
  },
  methods: {
    getExamStuOperationDetail() {
      let local = localStorage.getItem("role");
      if (local == 4 || local == 2) {
        this.showbBreadcrumb = false;
        let params = {
          new_kefu_log_id: this.$route.query.xlrzid,
        };
        getStuKefuTrainDetail(params)
          .then((res) => {
            if (res.data.robot_data.length) {
              res.data.robot_data.forEach((item) => {
                this.$set(item, "isShow", true);
              });
            }
            this.robotList = res.data.robot_data;
            this.score = res.data.score;
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (local == 0 || local == 1) {
        let params = {
          stu_id: this.$route.query.id,
          exam_id: this.$route.query.cId,
        };
        getStuKefuExamDetail(params)
          .then((res) => {
            if (res.data.robot_data.length) {
              res.data.robot_data.forEach((item) => {
                this.$set(item, "isShow", true);
              });
            }
            this.robotList = res.data.robot_data;
            this.score = res.data.score;
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    toDetail(obj) {
      localStorage.setItem("issueItem", JSON.stringify(obj));
      this.showDetail = false;
    },
    isShow() {
      this.showDetail = !this.showDetail;
      console.log(this.showDetail);
    },
  },
};
</script>

<style scoped lang="scss">
.data-header {
  width: 100%;

  margin-left: 30px;
  margin-top: 30px;
}
.service-data-scrollbar {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px 20px;
    }
  }
}
.service-data-header {
  padding: 0px 0 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f1f5ff;
  .header-left {
    display: flex;
    align-items: center;
    span {
      line-height: 1;
    }
  }
}
.service-data-table {
  margin-top: 20px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  .data-table-header {
    display: flex;
    background: #f6f6f6;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    span {
      color: #666666;
      font-size: 16px;
    }
    .table-header-item {
      flex: 1;
      width: 1%;
      box-sizing: border-box;
    }
  }
  .data-table-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .table-body-item {
      display: flex;
      flex-direction: column;
      .body-item-top,
      .body-item-bottom {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #eeeeee;
        .item-top-item,
        .item-bottom-item {
          width: 1%;
          flex: 1;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            //width: 100%;
            display: inline-block;
          }
          i {
            line-height: 1;
            cursor: pointer;
            font-size: 18px;
            color: #7d7d7d;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
